// @flow
import React, { Component } from 'react'
import { navigate, graphql } from 'gatsby'
import queryString from 'query-string'
import styled from 'styled-components'
import { lighten } from 'polished'
import { Container } from 'reactstrap'
import ReactMarkdown from 'react-markdown'

// Redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ActionCreators from '../store/actions'

import * as v from '../config/variables'

import { Button } from '../components/UI'
import Layout, { SEO } from '../components/Layout'
import mapAirtableData from '../lib/mapAirtableData'

type Props = { loginUser: Function, data: Object }

type State = { isLoading: boolean }

const LoginBox = styled.div`
  margin: 4rem auto;
  max-width: 330px;
  min-height: 200px;
  background-color: ${v.grayLighter};
  border-radius: 6px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
`

const MedForumLoginButton = styled(Button)`
  background-color: ${({ disabled }) =>
    disabled ? lighten(0.35, v.blue) : v.blue};
  color: ${v.white};
  display: block;
  width: 100%;
`

const getAuthorizeUrl = () => {
  if (typeof window !== 'undefined') {
    const params = queryString.parse(window.location.hash)

    const authorizeUrl = `${process.env.OAUTH_AUTHORIZE_URL}?client_id=${
      process.env.OAUTH_CLIENT_ID
    }&redirect_uri=${
      process.env.BASE_URL
    }/login/&response_type=token&scope=app${
      (params.return_url || {}).length
        ? `&return_url=/${params.return_url}`
        : ''
    }`

    return authorizeUrl
  }

  return ''
}

const userHasAuthorized = () => {
  if (typeof window !== 'undefined') {
    const params = queryString.parse(window.location.hash)
    if (
      (params.access_token || {}).length > 1 &&
      (params.token_type || {}) === 'bearer' &&
      (params.expires_in || {}).length > 1
    ) {
      return {
        accessToken: params.access_token,
        expiresIn: params.expires_in,
        returnUrl: params.return_url,
      }
    }
    return false
  }
  return false
}

class LoginPage extends Component<Props, State> {
  static loginMedforum(authorizeUrl: string) {
    if (typeof window !== 'undefined') {
      window.location.replace(authorizeUrl)
    }
  }

  constructor(props) {
    super(props)
    const { loginUser } = props

    const defaultRedirect = '/app/rezensionen'
    const loggedIn = (user) => (user || {}).isLoggedIn
    const authData = userHasAuthorized()

    if (loggedIn()) navigate(authData.returnUrl || defaultRedirect)

    if (authData)
      loginUser(authData).then(() => {
        navigate(authData.returnUrl || defaultRedirect)
      })

    this.state = {
      isLoading: authData !== false,
    }
  }

  render() {
    const { isLoading } = this.state
    const {
      data: { allAirtable },
    } = this.props
    const texts = mapAirtableData(allAirtable)

    const authorizeUrl = getAuthorizeUrl()

    return (
      <Layout>
        <Container>
          <SEO title="Login" />

          <LoginBox>
            <ReactMarkdown source={texts.Einleitungstext} />

            <MedForumLoginButton
              // as="a"
              type="button"
              href={authorizeUrl}
              disabled={isLoading}
              onClick={() => LoginPage.loginMedforum(authorizeUrl)}
            >
              {isLoading ? 'Wird geladen…' : 'Anmelden mit Medforum'}
            </MedForumLoginButton>
          </LoginBox>
          {/* <NewReviewForm /> */}
        </Container>
      </Layout>
    )
  }
}

function mapStateToProps({ user }) {
  return { user }
}

function mapDispatchToProps(dispatch: Function) {
  return {
    dispatch,
    ...bindActionCreators(ActionCreators, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "Login" } }) {
      edges {
        node {
          recordId
          data {
            Name
            Text
          }
        }
      }
    }
  }
`
